module.exports = [{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"showCopyButton":true,"buttonText":"コピー","toasterText":"コピーしました","tooltip":false,"toasterDuration":3000},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#a3621c","minimum":1,"easing":"liner","positionUsing":"","speed":200,"trickle":true,"trickleSpeed":200,"barSelector":"[role=\"bar\"]","spinnerSelector":"[role=\"spinner\"]","parent":"body","template":"<div class=\"bar\" role=\"bar\"><div class=\"peg\"></div></div><div class=\"spinner\" role=\"spinner\"><div class=\"spinner-icon\"></div></div>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"スムージーの備忘録","short_name":"スムージーの備忘録","start_url":"/","background_color":"#F5F5F5","theme_color":"#ffb78a","display":"browser","icons":[{"src":"static/favicon.ico","sizes":"32x32","type":"image/ico"},{"src":"static/favicon.ico","sizes":"48x48","type":"image/ico"},{"src":"static/favicon.ico","sizes":"64x64","type":"image/ico"},{"src":"static/favicon.ico","sizes":"128x128","type":"image/ico"},{"src":"static/favicon.ico","sizes":"256x256","type":"image/ico"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-S8MWV4RGMM"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
